type NextButtonProps = {
  isAnswerSelected: boolean;
  checkAnswer: () => void;
};

const nextButton = ({ isAnswerSelected, checkAnswer }: NextButtonProps) => {
  return (
    <div>
      <button className={`next-button display-flex justify-center max-width ${isAnswerSelected ? "active" : "inactive"}`} onClick={checkAnswer} disabled={!isAnswerSelected}>
        정답 확인하기
      </button>
    </div>
  );
};

export default nextButton;
