import AppstoreButton from "assets/images/button_appstore_download.png";
import PlaystoreButton from "assets/images/button_playstore_download.png";
import { FormattedMessage } from "react-intl";
import "./components.scss";
import Logo from "assets/images/logo.webp";
import "styles/buttons.scss";
import useAppDownload from "hooks/useAppDownload";
import { useMetaPixel } from "hooks/useMetaPixel";
import { FiInstagram } from "react-icons/fi";
import { useSocialLink } from "hooks/useSocialLink";

const Footer = () => {
  const { appStoreUrl, playStoreUrl } = useAppDownload();
  const { eventDownload, eventLead } = useMetaPixel();
  const { openSocialLink } = useSocialLink();

  const companyInfo = [{ id: "footer.company.name" }, { id: "footer.company.address" }, { id: "footer.company.eid" }, { id: "footer.company.email" }];
  const legalLinks = [
    { id: "footer.company.terms", path: "https://vocatale.co/terms.html" },
    { id: "footer.company.privacy", path: "https://vocatale.co/privacy.html" },
  ];

  return (
    <div className="navigation navigation-footer flex-col">
      <div className="justify-between flex-row">
        <img className="logo" src={Logo} alt="logo" width={100}></img>
        <div className="instagram cursor-pointer" onClick={() => openSocialLink("instagram")}>
          <FiInstagram style={{ color: "white", fontSize: "36" }}></FiInstagram>
        </div>
      </div>
      <div className="container-app-download-buttons flex-row max-width">
        <a href={playStoreUrl} target="_blank" rel="noreferrer">
          <img
            className="app-download-button"
            onClick={() => {
              eventDownload();
              eventLead();
            }}
            src={AppstoreButton}
            alt="appstore"
          />
        </a>
        <a href={appStoreUrl} target="_blank" rel="noreferrer">
          <img
            className="app-download-button"
            onClick={() => {
              eventDownload();
              eventLead();
            }}
            src={PlaystoreButton}
            alt="playstore"
          />
        </a>
      </div>

      <div className="container container-company-info justify-left max-width">
        {companyInfo.map((info, index) => (
          <div key={index} className="text-footer">
            <FormattedMessage id={info.id} />
          </div>
        ))}
      </div>

      <div className="container container-copyright text-footer max-width">
        <FormattedMessage id="footer.company.copyright" />
      </div>

      <div className="container container-legal-links flex-row max-width">
        {legalLinks.map((link, index) => (
          <div key={index} className="text-footer text-underline legal-links">
            <a href={link.path} target="_blank" rel="noreferrer">
              <FormattedMessage id={link.id} />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Footer;
