import { useState } from "react";
import AppPreviewDialogue from "./components/dialogueBox";
import MultipleChoice from "./components/multipleChoice";
import NextButton from "./components/nextButton";
import AnswerModal from "./components/answerModal";

export interface AppPreviewProps {
  answerSelected: number;
  answerSubmitted: boolean;
  dialogueText: string;
  isCorrect: boolean;
  dialogueAnswer: string;
  answerChoices: string[];
  answerIndex: number;
  explanation: string;
}

const AppPreview = () => {
  const [appPreviewProps, setAppPreviewProps] = useState<AppPreviewProps>({
    answerSelected: -1,
    answerSubmitted: false,
    isCorrect: false,
    dialogueText: "Are you completely out of your mind? Really? A detective?",
    dialogueAnswer: "mind",
    answerChoices: ["face", "mind", "place", "time"],
    answerIndex: 2,
    explanation: "Out of your mind는 흔히 사용되는 관용구로, '정신나간 것 아니야?' 라는 의미를 갖고 있어요. 다른 선택지들은 맥락과 어울리지 않아요.",
  });

  const checkAnswer = () => {
    if (appPreviewProps.answerSelected === -1) {
      return;
    }
    const isCorrect = appPreviewProps.answerChoices[appPreviewProps.answerSelected] === appPreviewProps.dialogueAnswer;
    setAppPreviewProps((prevProps) => ({
      ...prevProps,
      isCorrect: isCorrect,
      answerSubmitted: true,
    }));

    console.log("Is correct: " + isCorrect);
  };

  const setAnswerSelected = (index: number) => {
    setAppPreviewProps((prevProps) => {
      const updatedProps = {
        ...prevProps,
        answerSelected: index,
      };
      console.log("answer selected: " + updatedProps.answerSelected);
      return updatedProps;
    });
  };

  return (
    <div className="component-app-preview max-width display-flex align-bottom">
      <div className="flex-col max-width">
        <AppPreviewDialogue {...appPreviewProps} />
        {!appPreviewProps.answerSubmitted && (
          <>
            <MultipleChoice setAnswerSelected={setAnswerSelected} answerChoices={appPreviewProps.answerChoices} />
            <NextButton
              isAnswerSelected={appPreviewProps.answerSelected !== -1}
              checkAnswer={() => {
                checkAnswer();
              }}
            />
          </>
        )}
        {appPreviewProps.answerSubmitted && <AnswerModal isCorrect={appPreviewProps.isCorrect} answer={appPreviewProps.dialogueAnswer} explanation={appPreviewProps.explanation} />}
      </div>
    </div>
  );
};

export default AppPreview;
