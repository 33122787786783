import AppRouter from "./routers/router";
import "./styles/font.scss";
import "./styles/global.scss";
import "./styles/layouts.scss";
import "./styles/variables.scss";

function App() {
  return (
    <div className="App">
      <div className="fix-mobile-view margin-auto-all">
        <AppRouter />
      </div>
    </div>
  );
}

export default App;
