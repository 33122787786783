import PrivacyPage from "pages/legal/privacy";
import TermsPage from "pages/legal/terms";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "../pages/home/Home";
import NavbarSection from "components/Topbar";
import FooterSection from "components/Footer";
import { ReactNode } from "react";
import "styles/layouts.scss";

const AppRouter = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <PageWithNavbarAndFooter>
                <Home />
              </PageWithNavbarAndFooter>
            }
          />
          <Route path="/legal/terms" element={<TermsPage />} />
          <Route path="/legal/privacy" element={<PrivacyPage />} />
        </Routes>
      </Router>
    </>
  );
};

const PageWithNavbarAndFooter: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <>
      <NavbarSection />
      <div className="margin-top-navbar">{children}</div>
      <FooterSection />
    </>
  );
};

export default AppRouter;
