import { useState, useEffect } from "react";

type OS = "iOS" | "Android" | "unknown";

const useAppDownload = () => {
  const [os, setOs] = useState<OS>("unknown");
  const [storeUrl, setStoreUrl] = useState<string>("https://play.google.com/store/apps/details?id=com.apeiro.vocatale");
  const appStoreUrl = "https://apps.apple.com/kr/app/%EB%B3%B4%EC%B9%B4%ED%85%8C%EC%9D%BC/id6479695834";
  const playStoreUrl = "https://play.google.com/store/apps/details?id=com.apeiro.vocatale";

  useEffect(() => {
    const detectOS = (): "iOS" | "Android" | "unknown" => {
      const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

      // iOS detection
      const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream;
      if (isIOS) {
        setOs("iOS");
        setStoreUrl("https://apps.apple.com/kr/app/%EB%B3%B4%EC%B9%B4%ED%85%8C%EC%9D%BC/id6479695834");
      }

      // Android detection
      const isAndroid = /android/i.test(userAgent);
      if (isAndroid) {
        setOs("Android");
        setStoreUrl("https://play.google.com/store/apps/details?id=com.apeiro.vocatale");
      }

      return "unknown";
    };

    setOs(detectOS());
  }, []);

  return { os, storeUrl, appStoreUrl, playStoreUrl };
};

export default useAppDownload;
