import CardFront from "assets/images/home_features_3_card_front.webp";
import CardBack from "assets/images/home_features_3_card_back.webp";
import { FormattedMessage } from "react-intl";
import type { Swiper as SwiperType } from "swiper";
import { EffectFlip, Pagination, Navigation } from "swiper/modules";

// Import Swiper styles
import "swiper/css";

import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { useRef, useState } from "react";

const Features3Section = () => {
  const swiperRef = useRef<SwiperType | null>(null);

  const [isFront, setIsFront] = useState(true);

  return (
    <section className="section section-feature-3 flex-col">
      <div className="content-text">
        <div className="section-title">
          <FormattedMessage id="home.features.3.title" />
        </div>
        <div className="section-subtitle">
          <FormattedMessage id="home.features.3.subtitle" />
        </div>
      </div>
      <div className="card">
        <Swiper
          effect={"flip"}
          grabCursor={false}
          pagination={false}
          navigation={false}
          modules={[EffectFlip, Pagination, Navigation]}
          className="mySwiper"
          onSwiper={(swiper: any) => {
            swiperRef.current = swiper;
          }}
        >
          <SwiperSlide>
            <img className="max-width" src={CardFront} alt="card-front" />
          </SwiperSlide>
          <SwiperSlide>
            <img className="max-width" src={CardBack} alt="card-back" />
          </SwiperSlide>
        </Swiper>
      </div>

      <button
        className="button flip-button"
        onClick={() => {
          setIsFront(!isFront);
          if (isFront) {
            swiperRef?.current?.slideNext();
          } else {
            swiperRef?.current?.slidePrev();
          }
        }}
      >
        <FormattedMessage id="home.features.3.button" />
      </button>
    </section>
  );
};

export default Features3Section;
