import "./legal.scss";

const PrivacyPage = () => {
  return (
    <div className="page page-privacy">
      <h1>개인정보처리방침</h1> <h4>주식회사 아페로</h4>
      <p>
        안녕하세요. 주식회사 아페로입니다. 저희 회사는 이용자의 개인정보를 중요시하며, 개인정보 보호법을 상시 준수하고
        있습니다. 회사에 제공해주신 이용자의 소중한 개인정보를 어떠한 용도로 이용되고 있는지 알려드립니다.
      </p>
      <p>&nbsp;</p>
      <h3>제1조(개인정보의 처리 목적)</h3>
      <p>
        주식회사 아페로는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의
        용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등
        필요한 조치를 이행할 예정입니다.
      </p>
      <ol>
        <li>회사가 제공하는 앱 서비스 회원 가입 (모바일 앱, 홈페이지 등)</li>
        <li>회사가 제공하는 앱 서비스 제공 및 운영 (모바일 앱, 홈페이지 등)</li>
      </ol>
      <h3>&nbsp;</h3>
      <h3>제2조(개인정보의 처리 및 보유 기간)</h3>
      <p>
        귀하의 개인정보는 다음과 같이 개인정보의 수집목적 또는 제공받은 목적이 달성되면 파기하는 것을 원칙으로 합니다.
        그리고 상법, 전자상거래등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우
        회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의
        목적으로만 이용하며 보존기간은 아래와 같습니다.
      </p>
      <ul>
        <li>계약 또는 청약철회 등에 관한 기록 : 1 년</li>
        <li>대금결제 및 재화 등의 공급에 관한 기록 : 1 년</li>
        <li>소비자의 불만 또는 분쟁처리에 관한 기록 : 1 년</li>
        <li>리서치 조사 종료 후 3개월 후 파기</li>
      </ul>
      <p>&nbsp;</p>
      <h3>제 3조(처리하는 개인정보의 항목)</h3>
      <p>회사 다음의 개인정보 항목을 처리하고 있습니다.</p>
      <h4>회원가입 및 로그인을 위해 필요한 정보</h4>
      <table>
        <tbody>
          <tr>
            <td width="123">
              <p>구분</p>
            </td>
            <td width="132">
              <p>수집항목</p>
            </td>
            <td width="283">
              <p>수집 목적</p>
            </td>
            <td width="85">
              <p>보유 기간</p>
            </td>
          </tr>
          <tr>
            <td width="123">
              <p>회원가입</p>
            </td>
            <td width="132">
              <p>(필수) 아이디</p>
              <p>(필수) 이메일</p>
              <p>(필수) 비밀번호</p>
            </td>
            <td rowSpan={4} width={283}>
              <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 회원제 서비스 이용에 따른 본인 확인 절차에 이용</p>
              <p>&nbsp;</p>
              <p>
                &middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 고지사항 전달, 불만처리 등을 위한 원활한 의사소통 경로의
                확보
              </p>
              <p>&nbsp;</p>
              <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 새로운 서비스 및 신상품이나 이벤트 정보 등의 안내</p>
            </td>
            <td rowSpan={4} width={85}>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;탈퇴 후 1년</p>
            </td>
          </tr>
          <tr>
            <td width="123">
              <p>간편가입 카카오</p>
            </td>
            <td width="132">
              <p>(필수) SNS ID</p>
              <p>(필수) 이메일</p>
              <p>(필수) 핸드폰 번호</p>
            </td>
          </tr>
          <tr>
            <td width="123">
              <p>간편가입 네이버</p>
            </td>
            <td width="132">
              <p>(필수) SNS ID</p>
              <p>(필수) 아이디</p>
            </td>
          </tr>
          <tr>
            <td width="123">
              <p>간편가입 구글</p>
            </td>
            <td width="132">
              <p>(필수) 이메일</p>
            </td>
          </tr>
          <tr>
            <td width="123">
              <p>간편가입 애플</p>
            </td>
            <td width="132">
              <p>(선택) 이메일</p>
            </td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <h4>서비스 가입 및 이용시 고객이 입력한 정보</h4>
      <ul>
        <li>닉네임 또는 이름</li>
        <li>기타 맞춤형 서비스 제공을 위해 고객이 입력한 비민감 정보</li>
      </ul>
      <p>&nbsp;</p>
      <h3>제 4조(개인정보의 위탁처리)</h3>
      <p>회사 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.</p>
      <p>&nbsp;</p>
      <table>
        <tbody>
          <tr>
            <td width="208">
              <p>수탁업체</p>
            </td>
            <td width="208">
              <p>위탁업무</p>
            </td>
            <td width="208">
              <p>보유 기간</p>
            </td>
          </tr>
          <tr>
            <td width="208">
              <p>Amazon Web Services, Inc.</p>
            </td>
            <td width="208">
              <p>서비스 제공에 필요한 서버 운영</p>
            </td>
            <td width="208">
              <p>회원탈퇴 시 또는 법정 의무 보관 기간</p>
            </td>
          </tr>
          <tr>
            <td width="208">
              <p>OpenAI, L.L.C.</p>
            </td>
            <td width="208">
              <p>음성인식 기능.</p>
            </td>
            <td width="208">
              <p>회원탈퇴 시 또는 법정 의무 보관 기간</p>
            </td>
          </tr>
        </tbody>
      </table>
      <h3>&nbsp;</h3>
      <h3>제 5조 (개인정보의 파기절차 및 파기방법)</h3>
      <p>
        회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를
        파기합니다. 정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른
        법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나
        보관장소를 달리하여 보존합니다.
      </p>
      <p>&nbsp;</p>
      <p>개인정보 파기의 절차 및 방법은 다음과 같습니다.</p>
      <ul>
        <li>
          파기절차 : 회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를
          파기합니다.
        </li>
        <li>파기방법 : 전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다</li>
      </ul>
      <h3>&nbsp;</h3>
      <h3>제 6조 (정보주체와 법정대리인의 권리&middot;의무 및 그 행사방법에 관한 사항)</h3>
      <ol>
        <li>
          정보주체는 회사에 대해 언제든지 개인정보 열람&middot;정정&middot;삭제&middot;처리정지 요구 등의 권리를 행사할
          수 있습니다.
        </li>
        <li>
          제1항에 따른 권리 행사는 회사에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편,
          모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체 없이 조치하겠습니다.
        </li>
        <li>
          제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우
          &ldquo;개인정보 처리 방법에 관한 고시(제2020-7호)&rdquo; 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
        </li>
        <li>
          개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가
          제한 될 수 있습니다.
        </li>
        <li>
          개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를
          요구할 수 없습니다.
        </li>
        <li>
          회사는 정보주체 권리에 따른 열람의 요구, 정정&middot;삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가
          본인이거나 정당한 대리인인지를 확인합니다.
        </li>
      </ol>
      <p>&nbsp;</p>
      <h3>제 7조(개인정보의 안전성 확보조치에 관한 사항)</h3>
      <p>회사 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.</p>
      <ol>
        <li>내부관리계획의 수립 및 시행개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.</li>
        <li>
          개인정보에 대한 접근 제한개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여
          개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을
          통제하고 있습니다.
        </li>
      </ol>
      <p>&nbsp;</p>
      <h3>제 8조 (개인정보를 자동으로 수집하는 장치의 설치, 운영 및 그 거부에 관한 사항)</h3>
      <p>
        회사는 정보주체의 이용정보를 저장하고 수시로 불러오는 &lsquo;쿠키(cookie)&rsquo;를 사용하고 있습니다. 쿠키는
        회사 웹사이트 운영에 이용되는 서버가 사용자의 브라우저에 보내는 아주 작은 텍스트 파일로, 사용자의 기기내
        저장공간에 저장됩니다. 다음과 같은 목적으로 쿠키를 사용합니다.
      </p>
      <ul>
        <li>자동 로그인 기능 구현</li>
        <li>회원/비회원의 접속 빈도 또는 방문 시간 등 분석</li>
        <li>사용자의 취향과 관심분야 파악 및 사용 기록 확인</li>
        <li>각종 이벤트 참여 및 방문 횟수 파악 등을 통한 타겟 마케팅과 개인 맞춤 서비스 제공 등</li>
      </ul>
      <p>&nbsp;</p>
      <h3>제 9조( 추가적인 이용&middot;제공 판단기준)</h3>
      <p>
        회사는 ｢개인정보 보호법｣ 제15조 제3항 및 제17조 제4항에 따라 ｢개인정보 보호법 시행령｣ 제14조의 2에 따른 사항을
        고려하여 정보주체의 동의 없이 개인정보를 추가적으로 이용&middot;제공할 수 있습니다.이에 따라 회사가 정보주체의
        동의 없이 추가적인 이용&middot;제공을 하기 위해서 다음과 같은 사항을 고려하였습니다.
      </p>
      <ul>
        <li>개인정보를 추가적으로 이용&middot;제공하려는 목적이 당초 수집 목적과 관련성이 있는지 여부</li>
        <li>
          개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때 추가적인 이용&middot;제공에 대한 예측 가능성이 있는지
          여부
        </li>
        <li>개인정보의 추가적인 이용&middot;제공이 정보주체의 이익을 부당하게 침해하는지 여부</li>
        <li>가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지 여부</li>
        <li>
          추가적인 이용&middot;제공 시 고려사항에 대한 판단기준은 사업자/단체 스스로 자율적으로 판단하여 작성 &middot;
          공개함
        </li>
      </ul>
      <p>&nbsp;</p>
      <h3>제 10조 (가명정보를 처리하는 경우 가명정보 처리에 관한 사항)</h3>
      <p>회사는 가명정보를 처리하고 있지 않습니다.</p>
      <p>&nbsp;</p>
      <h3>제 11조 (개인정보 보호책임자에 관한 사항)</h3>
      <p>
        회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제
        등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
      </p>
      <h4>보호책임자</h4>
      <ul>
        <li>성명 &nbsp;&nbsp;&nbsp; : 주민형</li>
        <li>직책 &nbsp;&nbsp;&nbsp; : 대표</li>
        <li>직급 &nbsp;&nbsp;&nbsp; : 대표</li>
        <li>연락처 : mjoo@apeirocorp.com</li>
      </ul>
      <h4>개인정보 보호 담당부서</h4>
      <ul>
        <li>부서명&nbsp; : 운영팀</li>
        <li>담당자&nbsp; : 이용채</li>
        <li>연락처&nbsp; : yclee@apeirocorp.com</li>
      </ul>
      <p>&nbsp;</p>
      <h3>제 12조 (국내대리인의 지정)</h3>
      <p>
        정보주체는 ｢개인정보 보호법｣ 제39조의11에 따라 지정된 회사의 국내대리인에게 개인정보 관련 고충처리 등의 업무를
        위하여 연락을 취할 수 있습니다. 회사 정보주체의 개인정보 관련 고충처리 등 개인정보 보호책임자의 업무 등을
        신속하게 처리할 수 있도록 노력하겠습니다. 현재, 회사는 국내대리인을 지정하지 않고 있습니다. &nbsp;
      </p>
      <p>&nbsp;</p>
      <h3>제 13조 (개인정보의 열람청구를 접수&middot;처리하는 부서)</h3>
      <p>
        정보주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다.회사 정보주체의
        개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
      </p>
      <h4>개인정보 열람청구 접수&middot;처리 부서</h4>
      <ul>
        <li>부서명 : 운영팀</li>
        <li>연락처 : privacy@apeirocorp.com</li>
      </ul>
      <h3>&nbsp;</h3>
      <h3>제 14조 (정보주체의 권익침해에 대한 구제방법)</h3>
      <p>
        정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터
        등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에
        문의하시기 바랍니다.
      </p>
      <ul>
        <li>
          개인정보분쟁조정위원회 : (국번없이) 1833-6972 (<a href="http://www.kopico.go.kr">kopico.go.kr</a>)
        </li>
        <li>개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)</li>
        <li>
          대검찰청 : (국번없이) 1301 (<a href="http://www.spo.go.kr">spo.go.kr</a>)
        </li>
        <li>경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)</li>
      </ul>
      <p>&nbsp;</p>
      <p>
        「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정&middot;삭제), 제37조(개인정보의 처리정지 등)의
        규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는
        행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다. 행정심판에 대해 자세한 사항은
        중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.
      </p>
      <p>&nbsp;</p>
      <h3>제 15조 (영상정보처리기기 운영&middot;관리에 관한 사항)</h3>
      <p>회사 영상정보처리기기를 운영하고 있지 않습니다.</p>
      <h3>&nbsp;</h3>
      <h3>제 16조 (개인정보 처리방침 변경)</h3>
      <p>이 개인정보처리방침은 2024년 6월 24일부터 적용됩니다.</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </div>
  );
};

export default PrivacyPage;
