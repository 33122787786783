import { HamburgerIcon, DownloadIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import "styles/buttons.scss";
import "./components.scss";

import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import Logo from "assets/images/logo.webp";
import { useEffect, useState } from "react";
import useAppDownload from "hooks/useAppDownload";

const Topbar = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const { storeUrl } = useAppDownload();

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > lastScrollTop && scrollTop > 52) {
        // 52 is the navbar height
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
      setLastScrollTop(scrollTop);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollTop]);

  const menu = () => {
    return (
      <div className="chakra-menu">
        <Menu placement="top" preventOverflow={true}>
          <MenuButton as={Button} rightIcon={<HamburgerIcon boxSize={5} color="white" />} sx={{ backgroundColor: "transparent", color: "none" }}></MenuButton>

          <MenuList zIndex="999">
            {/* <MenuItem>이용약관</MenuItem> */}
            <MenuItem>앱 체험하기 (예정)</MenuItem>
          </MenuList>
        </Menu>
      </div>
    );
  };

  const downloadButton = () => {
    return (
      <div className="button-download">
        <a href={storeUrl} target="_blank" rel="noreferrer">
          <DownloadIcon boxSize={4} color="white" marginRight={2} />
          시작하기
        </a>
      </div>
    );
  };

  return (
    <div className={`navigation navigation-topbar ${isVisible ? "" : "navigation-topbar--hidden"} display-flex flex-row max-width justify-between fix-mobile-view`}>
      <img className="logo" src={Logo} alt="logo"></img>
      {/* {menu()} */}
      {downloadButton()}
    </div>
  );
};

export default Topbar;
