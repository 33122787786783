import { FormattedMessage } from "react-intl";

const TransitionSection = () => {
  return (
    <section className="section section-transition flex-col">
      <div className="section-title">
        <FormattedMessage id="home.transition.title" />
      </div>
    </section>
  );
};

export default TransitionSection;
