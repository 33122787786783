export interface AnswerModalProps {
  answer: string;
  isCorrect: boolean;
  explanation: string;
}

const AnswerModal = (props: AnswerModalProps) => {
  return (
    <div className="answer-modal max-width flex-column justify-left show">
      <h3 className={`title ${props.isCorrect ? "correct" : "incorrect"}`}>정답 : {props.answer}</h3>
      <h4 className="explanation">{props.explanation}</h4>
    </div>
  );
};

export default AnswerModal;
