import { useState } from "react";
import { IoIosRemove } from "react-icons/io";
import { IoAdd } from "react-icons/io5";
import { FormattedMessage } from "react-intl";

// Import Swiper styles
import "swiper/css";

const FaqSection = () => {
  const faqItemsCountList = [1, 2, 3, 4];
  const faqItems = faqItemsCountList.map((item: any, index: number) => {
    const question = `home.faq.item.${index + 1}.question`;
    const answer = `home.faq.item.${index + 1}.answer`;
    return {
      title: question,
      content: answer,
    };
  });

  const [revealedItems, setRevealedItems] = useState<number[]>([]);

  const toggleReveal = (index: number) => {
    if (revealedItems.includes(index)) {
      setRevealedItems(revealedItems.filter((item) => item !== index));
    } else {
      setRevealedItems([...revealedItems, index]);
    }
  };

  const itemBox = (index: number) => {
    return (
      <div className="faq-item" key={index}>
        <div className="flex-row  justify-between">
          <div className="faq-item-title">
            <FormattedMessage id={`home.faq.item.${index + 1}.question`} />
          </div>
          {revealedItems.includes(index) ? <IoIosRemove className="faq-button" onClick={() => toggleReveal(index)} /> : <IoAdd className="faq-button" onClick={() => toggleReveal(index)}></IoAdd>}
        </div>
        {revealedItems.includes(index) && <div className="faq-item-content">{<FormattedMessage id={`home.faq.item.${index + 1}.answer`} />}</div>}
        {index + 1 !== faqItemsCountList.length ? <hr className="faq-item-hr" /> : null}
      </div>
    );
  };

  return (
    <section className="section section-faq flex-col" id="faq">
      <div className="section-title">
        <FormattedMessage id="home.faq.title" />
      </div>
      <div className="faq-container flex-col max-width">
        <div className="faq-items flex-col max-width">{faqItems.map((item, index) => itemBox(index))}</div>
      </div>
    </section>
  );
};

export default FaqSection;
