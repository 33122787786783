import useAppDownload from "hooks/useAppDownload";
import "./components.scss";
import { useMetaPixel } from "hooks/useMetaPixel";

const ActionButton = ({ buttonText }: { buttonText: string }) => {
  const { storeUrl } = useAppDownload();
  const { eventDownload, eventLead } = useMetaPixel();

  return (
    <a href={storeUrl} target="_blank" rel="noreferrer">
      <div className="component component-action-button">
        <button
          className="action-button"
          onClick={() => {
            eventDownload();
            eventLead();
          }}
        >
          {buttonText}
        </button>
      </div>
    </a>
  );
};

export default ActionButton;
