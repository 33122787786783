import { FormattedMessage } from "react-intl";
import AppPreview from "../components/appPreview/AppPreview";
import star_on from "assets/images/icon_star_on.webp";
import star_off from "assets/images/icon_star_off.webp";
import "styles/colors.scss";

const ProblemSection = () => {
  return (
    <section className="section section-problem flex-col">
      <div className="section-title color-white">
        <FormattedMessage id="home.problem.title" />
      </div>
      <div className="section-subtitle">
        <div className="label-container flex-row">
          <div className="label-name">
            <FormattedMessage id="home.problem.label.difficulty.name" />
          </div>
          <div className="label-value flex-row">
            <img className="star" src={star_on} alt="star" />
            <img className="star" src={star_on} alt="star" />
            <img className="star" src={star_off} alt="star" />
          </div>
        </div>
        <div className="label-container flex-row">
          <div className="label-name">
            <FormattedMessage id="home.problem.label.toeic.name" />
          </div>
          <div className="label-value label-toeic flex-row ">
            <FormattedMessage id="home.problem.label.toeic.value" />
          </div>
        </div>
      </div>

      <div className="section-content image-width-limit">
        <div className="app-preview-action-button">
          <FormattedMessage id="home.problem.button" />
        </div>
        <AppPreview />
      </div>
    </section>
  );
};

export default ProblemSection;
