import React from "react";

export const useSocialLink = () => {
  const socialLinks = {
    instagram: "https://www.instagram.com/voca.tale/",
  };

  const openSocialLink = React.useCallback((platform: keyof typeof socialLinks) => {
    let url = socialLinks[platform];
    if (url) {
      window.open(url, "_blank", "noopener,noreferrer");
    }
  }, []);

  return { socialLinks, openSocialLink };
};
