import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { IntlProvider } from "react-intl";
import messages from "./i18n/messages";
import { ChakraProvider } from "@chakra-ui/provider";
import { extendTheme } from "@chakra-ui/react";
import ReactPixel from "react-facebook-pixel";
import "styles/global.scss";

// Get browser language
const language = navigator.language.split(/[-_]/)[0];
const locale = ["en", "kr"].includes(language) ? "kr" : "kr";

// Initialize Facebook Pixel
ReactPixel.init("386255627343002");
ReactPixel.pageView();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <ChakraProvider theme={extendTheme({})}>
    <IntlProvider locale={locale} messages={messages[locale as keyof typeof messages]}>
      <App />
    </IntlProvider>
  </ChakraProvider>
);

reportWebVitals();
