import "./Home.scss";
import Features1Section from "./sections/feature1";
import Features2Section from "./sections/feature2";
import Features3Section from "./sections/feature3";
import HeadlineSection from "./sections/headline";
import ProblemSection from "./sections/problem";
import TransitionSection from "./sections/transition";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-flip";
import "swiper/css/navigation";
import "swiper/css/pagination";
import ActionSection from "./sections/action";
import FaqSection from "./sections/faq";

// Import SCSS
import "styles/buttons.scss";
import "styles/colors.scss";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const Home = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <div className="page page-home flex-col">
      <HeadlineSection />
      <ProblemSection />
      {/* <TestimonialSection /> */}
      <TransitionSection />
      <Features1Section />
      <Features2Section />
      <Features3Section />
      <ActionSection />
      <FaqSection />
    </div>
  );
};

export default Home;
