import { FormattedMessage } from "react-intl";
import ActionButton from "../components/ActionButton";

const ActionSection = () => {
  return (
    <section className="section section-action">
      <div className="action-content">
        <div className="section-label">
          <FormattedMessage id="action.label" />
        </div>
        <div className="section-title">
          <FormattedMessage id="action.title" />
        </div>
        <ActionButton buttonText={"지금 시작하기"} />
      </div>
    </section>
  );
};

export default ActionSection;
