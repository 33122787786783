import { FormattedMessage } from "react-intl";
import ActionButton from "../components/ActionButton";

const HeadlineSection = () => {
  return (
    <section className="section section-headline flex-col">
      <div className="page-title">
        <FormattedMessage id="home.headline.title" />
      </div>
      <div className="page-subtitle">
        <FormattedMessage id="home.headline.subtitle" />
      </div>
      <ActionButton buttonText={"무료로 시작하기"} />
    </section>
  );
};

export default HeadlineSection;
