import { FormattedMessage } from "react-intl";
import { AppPreviewProps } from "../AppPreview";

const DialogueText = (appPreviewProps: AppPreviewProps) => {
  const placeholder = appPreviewProps.dialogueAnswer;

  const displayText = () => {
    if (appPreviewProps.answerSubmitted) {
      return appPreviewProps.dialogueText.replace(placeholder, appPreviewProps.dialogueAnswer);
    }
    return appPreviewProps.dialogueText.replace(placeholder, "_________");
  };
  return (
    <div className="dialogue-text">
      <span dangerouslySetInnerHTML={{ __html: displayText() }}></span>
    </div>
  );
};

const DialogueBox = (appPreviewProps: AppPreviewProps) => {
  return (
    <div className="dialogue-box-container">
      <div className="flex-row justify-between">
        <div className="character-name">
          <h4>Jack</h4>
        </div>
        <div className="toeic-label flex-row">
          <span className="label-emphasis">700점</span>
          <span className="label">핵심</span>
          <span className="label-emphasis">Part 5</span>
          <span className="label">기출</span>
        </div>
      </div>
      <div className="dialogue max-width">
        <DialogueText {...appPreviewProps} />
      </div>
    </div>
  );
};

export default DialogueBox;
