import App from "assets/images/home_features_2_app.webp";
import { FormattedMessage } from "react-intl";

// Import Swiper styles
import "swiper/css";

const Features2Section = () => {
  return (
    <section className="section section-feature-2 flex-col">
      <div className="section-title">
        <FormattedMessage id="home.features.2.title" />
      </div>
      <div className="section-subtitle">
        <FormattedMessage id="home.features.2.subtitle" />
      </div>
      <img className="app-image" src={App} alt="app" />
    </section>
  );
};

export default Features2Section;
