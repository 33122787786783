import Opic from "assets/images/home_feature_1_opic_icon.webp";
import Poster1 from "assets/images/home_feature_1_poster_1.webp";
import Poster2 from "assets/images/home_feature_1_poster_2.webp";
import Poster3 from "assets/images/home_feature_1_poster_3.webp";
import Posters from "assets/images/home_feature_1_posters.webp";
import ToeicSpeaking from "assets/images/home_feature_1_speaking_icon.webp";
import Toeic from "assets/images/home_feature_1_toeic_icon.webp";
import { FormattedMessage } from "react-intl";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { FreeMode, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const Features1Section = () => {
  const toeicImages = [Toeic, ToeicSpeaking, Opic];

  return (
    <section className="section section-feature-1 flex-col">
      <div className="section-title">
        <FormattedMessage id="home.features.1.title" />
      </div>
      <div className="section-subtitle">
        <FormattedMessage id="home.features.1.subtitle" />
      </div>

      <div className="toeic-images flex-row">
        {toeicImages.map((image, index) => {
          return (
            <div key={index}>
              <img src={image} className="toeic-image" alt="toeic" />
            </div>
          );
        })}
      </div>

      <img src={Posters} className="poster-image" alt="posters" />
      {/* <Swiper
          slidesPerView={2}
          spaceBetween={30}
          freeMode={true}
          pagination={{
            clickable: true,
          }}
          modules={[FreeMode, Pagination]}
          className="mySwiper"
        >
          <SwiperSlide>
            <img className="poster-image" src={Poster1}></img>
          </SwiperSlide>
          <SwiperSlide>
            <img className="poster-image" src={Poster2}></img>
          </SwiperSlide>
          <SwiperSlide>
            <img className="poster-image" src={Poster3}></img>
          </SwiperSlide>
          <SwiperSlide>
            <img className="poster-image" src={Poster1}></img>
          </SwiperSlide>
          <SwiperSlide>
            <img className="poster-image" src={Poster2}></img>
          </SwiperSlide>
          <SwiperSlide>
            <img className="poster-image" src={Poster3}></img>
          </SwiperSlide>
        </Swiper> */}
    </section>
  );
};

export default Features1Section;
