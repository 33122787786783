import { useState } from "react";

export interface MultipleChoiceProps {
  answerChoices: string[];
  setAnswerSelected: (index: number) => void;
}

const Button = ({ label, onClick, isSelected }: { label: string; onClick: () => void; isSelected: boolean }) => (
  <button className={`button ${isSelected ? "active" : "inactive"}`} onClick={onClick}>
    {label}
  </button>
);

const MultipleChoice = ({ answerChoices, setAnswerSelected }: MultipleChoiceProps) => {
  const [selectedMultipleChoiceIndex, setSelectedMultipleChoiceIndex] = useState<number>();

  const handleAnswerClick = (index: number) => {
    setSelectedMultipleChoiceIndex(index);
    setAnswerSelected(index);
    console.log("Selected index: ", index);
  };

  return (
    <div className="multiple-choice-buttons max-width">
      <div className="button-container">
        {answerChoices.map((choice, index) => (
          <div className="button" key={index}>
            <Button label={choice} onClick={() => handleAnswerClick(index)} isSelected={selectedMultipleChoiceIndex === index} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultipleChoice;
