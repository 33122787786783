import ReactPixel from "react-facebook-pixel";
import React from "react";

export const useMetaPixel = () => {
  const eventDownload = React.useCallback(() => {
    ReactPixel.trackCustom("download");
  }, []);

  const eventLead = React.useCallback(() => {
    ReactPixel.track("Lead");
  }, []);

  return { eventDownload, eventLead };
};
